import React, { useState, useEffect, useMemo } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import useClasses from "./UseClass";
// import { createTheme, ThemeProvider } from "@mui/material/styles";

import SkipNextIcon from "@mui/icons-material/SkipNext";


// Import background images
import background01 from "../public/Background01.png";
import background02 from "../public/Background02.png";
import background03 from "../public/Background03.png";
import background04 from "../public/Background04.png";
import background05 from "../public/Background05.png";
import background06 from "../public/Background06.png";
import background07 from "../public/Background07.png";
import background08 from "../public/Background08.png";
import background09 from "../public/Background09.png";
import background10 from "../public/Background10.png";
import background11 from "../public/Background11.png";
import background12 from "../public/Background12.png";
import background13 from "../public/Background13.png";
import background14 from "../public/Background14.png";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
  },
  // leftColumn: {
  //   padding: theme.spacing(4),
  // },
  // grid: {
  //   marginTop: theme.spacing(2),
  //   marginBottom: theme.spacing(2),
  // },
  rightColumn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: theme.spacing(2),
    minHeight: "600px", // ensures the column is tall enough
  },
  backgroundImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover", // image covers the container
  }
});

// const theme = createTheme({
//   palette: {
//     mode: "light", 
//   },
// });

const Home = () => {
  const classes = useClasses(styles);
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();


  // Random background image state
  const [bgImage, setBgImage] = useState(null);

  // Memoize the backgrounds array so it doesn't change on each render.
  const backgrounds = useMemo(() => [
    background01,
    background02,
    background03,
    background04,
    background05,
    background06,
    background07,
    background08,
    background09,
    background10,
    background11,
    background12,
    background13,
    background14
  ], []);

  // Pick one random background image on mount
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * backgrounds.length);
    setBgImage(backgrounds[randomIndex]);
  }, [backgrounds]);


  const handleRoute = () => {
    navigate("/stock");
  };

  const login = async () => {
    if (!authState.isAuthenticated) {
      oktaAuth.signInWithRedirect();
    }
  };

  const today = new Date();
  const curHr = today.getHours();
  const greeting = curHr < 12 ? "Good Morning" : curHr < 19 ? "Good Afternoon" : "Good Evening";

  // Extract user details from authState
  const name = authState?.idToken?.claims?.name;
  const email = authState?.idToken?.claims?.email;

  return (
    // <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Grid2 container>
          {/* Left Column with Content */}
          <Grid2 xs={12} md={6} className={classes.leftColumn}>
            <Typography variant="h3">{greeting}</Typography>
            {authState && authState.isAuthenticated && authState.idToken ? (
              <div>
                <Typography variant="h6">
                  Welcome back, {name} ({email})!
                </Typography>
                {/* <Typography variant="h6" gutterBottom>
                  Market Today
                </Typography> */}
                {/* Example market image */}
                {/* Assuming marketImage is defined elsewhere */}
                {/* {marketImage && (
                  <img src={marketImage} alt="market condition" style={{ width: "100px" }} />
                )} */}
                {/* <Grid2 container className={classes.grid} spacing={2} direction="row">
                  {dowJonesChange !== null && (
                    <Grid2 xs={3}>
                      <Box
                        bgcolor={parseFloat(dowJonesChange) >= 0 ? "success.main" : "error.main"}
                        color="success.contrastText"
                        p={2}
                      >
                        {parseFloat(dowJonesChange) >= 0 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        &nbsp; DJIdx: <b>{dowJonesChange}%</b>
                      </Box>
                    </Grid2>
                  )}
                  {sp500Change !== null && (
                    <Grid2 xs={3}>
                      <Box
                        bgcolor={parseFloat(sp500Change) >= 0 ? "success.main" : "error.main"}
                        color="success.contrastText"
                        p={2}
                      >
                        {parseFloat(sp500Change) >= 0 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        &nbsp; SP500: <b>{sp500Change}%</b>
                      </Box>
                    </Grid2>
                  )}
                  {nasdaqChange !== null && (
                    <Grid2 xs={3}>
                      <Box
                        bgcolor={parseFloat(nasdaqChange) >= 0 ? "success.main" : "error.main"}
                        color="success.contrastText"
                        p={2}
                      >
                        {parseFloat(nasdaqChange) >= 0 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        &nbsp; Nasdaq: <b>{nasdaqChange}%</b>
                      </Box>
                    </Grid2>
                  )}
                </Grid2> */}
                <Button
                  style={{ marginBottom: 12, marginTop: 12 }}
                  onClick={handleRoute}
                  variant="contained"
                  color="primary"
                  startIcon={<SkipNextIcon />}
                >
                  Go to Stock Page
                </Button>
              </div>
            ) : (
              <section>
                <Typography variant="h5">
                  Stock Watcher collects realtime stock market data,
                </Typography>
                <Typography variant="body1">
                  Processes analysis by AI and Data Science methods on your selected equity.
                  You can measure your current position and improve your strategy.
                </Typography>
                <Typography variant="body1">Start Your Stock Watcher Today!</Typography>
                <Box mt={2}>
                  <Button variant="contained" color="primary" onClick={login}>
                    Login / Sign Up
                  </Button>
                </Box>
              </section>
            )}
            <Box mt={2}>
              <Typography variant="body2" color="textSecondary">
                Powered by OpenAI, Yahoo Finance, and more.
              </Typography>
            </Box>
          </Grid2>

          {/* Right Column with Background Image */}
          <Grid2 xs={12} md={6} className={classes.rightColumn}>
            {bgImage && (
              <img src={bgImage} alt="Background" className={classes.backgroundImg} />
            )}
          </Grid2>
        </Grid2>
      </div>
    //{/* </ThemeProvider> */}
  );
};

export default Home;





