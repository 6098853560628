import React from 'react';
import FetchMongoDbAPI from "./components/FetchMongoDbAPI.jsx";
import "./css/Home.css";


function Review() {

  


  return (
    
    <div>
    <FetchMongoDbAPI/>

    </div>
  );
}

export default Review;