// WeekRangeBar.jsx
import React from 'react';
import { Box, Typography } from '@mui/material';

const PriceRangeBar = ({ low, high, current }) => {
  // Calculate the position (percentage) of the current price within the range
  const percentage = ((current - low) / (high - low)) * 100;

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        52 Week Range: ${low} - ${high}
      </Typography>
      <Box
        sx={{
          position: 'relative',
          height: 12,
          backgroundColor: 'grey.300',
          borderRadius: '6px'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: `${percentage}%`,
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 16,
            height: 16,
            borderRadius: '50%',
            backgroundColor: 'primary.main',
            border: '2px solid white',
          }}
        />
      </Box>
      <Typography
        variant="caption"
        sx={{ mt: 1, display: 'block', textAlign: 'center' }}
      >
        Current Price: {current}
      </Typography>
    </Box>
  );
};

export default PriceRangeBar;
