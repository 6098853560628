// hooks/useStockData.js
import { useState, useEffect } from 'react';
import axios from 'axios';

export const useNewsData = (symbol, mySymbolName) => {
  const [cityNews, setCityNews] = useState(null);

  useEffect(() => {
    const fetchNewsData = async () => {
      // setLoading(true);

      const options = {
        method: 'GET',
        url: 'https://real-time-news-data.p.rapidapi.com/search',
        params: {
          query: symbol+" "+mySymbolName,
          limit: '10',
          time_published: 'anytime',
          country: 'US',
          lang: 'en'
        },
        headers: {
          'x-rapidapi-key': process.env.REACT_APP_NEWSDATA_KEY,
          'x-rapidapi-host': 'real-time-news-data.p.rapidapi.com'
        }
      };
      
      

      try {
        try {
          await axios.request(options)
            .then(function (response) {
              setCityNews(response.data.data)
            })
        } catch (error) {
          console.error("Error fetching News API real-time-news-data.p.rapidapi.com:", error);
        };

      } catch (error) {
        console.error("API call has at least 1 error: " + error);
      }


    }

    // When symbol changes, fetch the stock data.
    if (symbol) {
      fetchNewsData(symbol);
    }
  }, [symbol,mySymbolName]);

  return {
     cityNews

  };
};


