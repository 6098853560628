import React, { useState, useEffect } from 'react';
import OpenAI from 'openai';
import axios from 'axios';
import { Button, Paper, Typography, CircularProgress } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const AiSummarization = ({ content0, content1, input, user_name, Descriptions, Brand }) => {
  const [summary, setSummary] = useState('');
  const [loading, setLoading] = useState(false);
  const [lastSummarizedInput, setLastSummarizedInput] = useState("");

  const runSummarization = async () => {
    if (!input) return;
    setLoading(true);
    try {
      const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAIAPI_KEY, // Replace with your API key
        dangerouslyAllowBrowser: true,
      });

      const response = await openai.chat.completions.create({
        model: 'gpt-4o',
        messages: [content0, content1],
        store: true,
      });
      const summaryText = response.choices[0].message.content;
      setSummary(summaryText);

      // Save the summarization result to your backend if summaryText is not empty.
      if (summaryText !== "") {
        axios
          .post("https://20211205t040925-dot-my-comment-api-dot-mingfei-test-account.appspot.com/api/comments", {
            comment_name: summaryText,
            user_name: user_name,
            Rating: 0,
            Description: Descriptions,
            Brand: Brand,
          })
          .then((res) => {
            console.log("Summarization is saved");
          })
          .catch((error) => {
            console.log("Error posting comment:", error.response);
          });
      }
      // Save the input that produced this summary.
      setLastSummarizedInput(input);
    } catch (error) {
      console.error("Error during AI Summarization:", error);
      setSummary("Error during AI Summarization");
    } finally {
      setLoading(false);
    }
  };

  // If the input changes (and it's different than what generated the summary), clear the summary.
  useEffect(() => {
    if (summary && lastSummarizedInput !== input) {
      setSummary("");
    }
  }, [input, lastSummarizedInput, summary]);

  // Disable the button if loading, or if a summary exists and input hasn't changed.
  const isButtonDisabled = loading || (summary && lastSummarizedInput === input);

  return (
    <div>
      {input &&(<div style={{ marginTop: '20px' }}>
        <Button
          variant="contained"
          onClick={runSummarization}
          disabled={isButtonDisabled}
          color="secondary"
          sx={{ mt: 2 }}
        >
          {loading ? "Summarizing..." : (
            <>
              <AutoAwesomeIcon sx={{ mr: 1 }} />
              AI Summarization
            </>
          )}
        </Button>
      </div>)}

      {summary && (
        <Paper
          elevation={3}
          sx={{ mt: 2, p: 2, backgroundColor: 'rgba(247,247,247,0.2)', borderRadius: 2 }}
        >
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", mb: 1 }}
          >
            <AutoAwesomeIcon sx={{ mr: 1 }} /> AI Summarization
          </Typography>
          <Typography
            variant="body1"
            sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
          >
            {summary}
          </Typography>
        </Paper>
      )}

      {loading && (
        <div style={overlayStyle}>
          <CircularProgress style={spinnerStyle} />
        </div>
      )}
    </div>
  );
};

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const spinnerStyle = {
  color: "#ffffff",
};

export default AiSummarization;




