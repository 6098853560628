import React, { useState } from "react";
import axios from "axios";
import { withOktaAuth } from "@okta/okta-react";

import hot from "../../public/hot.png";
import warm from "../../public/warm.png";
import cool from "../../public/cool.png";
import cold from "../../public/cold.png";
import freeze from "../../public/freeze.png";

import WarningIcon from "@mui/icons-material/Warning";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";

import { Line, Bar } from "react-chartjs-2";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

import cities_json_file from "../data/mycitycountry.json";
// Google Maps imports
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PictureApi from "./PictureApi";
import DescriptionIcon from "@mui/icons-material/Description";

import { Divider } from "@mui/material";
import { useNewsData } from "../hooks/useNewsData";
import NewsFeed from "./NewsFeed";

const darkskyAPIkey = process.env.REACT_APP_DARKSKYAPIKEY;
const bigdatacloudAPIkey = process.env.REACT_APP_BIGDATACLOUDAPIKEY;
const googleMapAPIkey = process.env.REACT_APP_GOOGLEMAP_KEY;

// Define custom style objects
const styles = {
  container: {
    padding: "20px",
    maxWidth: "1200px",
    margin: "auto",
    fontFamily: "'Roboto', sans-serif",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  chartArea: {
    display: "flex",
    position: "relative",
    marginTop: 25,
    minHeight: 280,
    maxHeight: 420,
  },
  grid: {
    marginTop: 6,
    marginBottom: 6,
    flexGrow: 1,
  },
  divider: {
    width: "50%",
    borderColor: "#1976d2",
    borderWidth: 2,
  },
};

// --- Helper Functions ---
function capitalizeFirstLetter(str) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function epochToDate(ts) {
  const d = new Date(ts * 1000);
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const date = d.getDate().toString().padStart(2, "0");
  return `${d.getFullYear()}-${month}-${date}`;
}

function getWeekday(ts) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const d = new Date(ts * 1000);
  return days[d.getDay()];
}

function getForecastStyle(temp) {
  if (temp >= 25) {
    return { bg: "#a991f7", icon: hot };
  } else if (temp >= 10) {
    return { bg: "#37cdbe", icon: warm };
  } else if (temp >= 0) {
    return { bg: "#a991f7", icon: cool };
  } else if (temp >= -10) {
    return { bg: "#49C2C7", icon: cold };
  } else {
    return { bg: "#5EACC2", icon: freeze };
  }
}

const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();
const filterOptions = (options, state) =>
  defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);

const cities_json = cities_json_file["coordinations"];
const cities = Object.keys(cities_json);

// -- The Class Component --
class FetchWeatherApi extends React.Component {
  constructor(props) {
    super(props);
    // Note: cityname and countryName are lifted to the wrapper.
    this.state = {
      currentCoordinate: [15.5689, -79.564658],
      principalSubdivision: null,
      condition: null,
      icon: null,
      temperature: null,
      forecasts: [],
      lineHistData: {},
      barChartData: {},
      sunLineData: {},
      alertMsgList: [],
      loading: false,
    };
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.fetchWeatherData(pos.coords.latitude, pos.coords.longitude);
      },
      (err) => {
        console.error("Geolocation error:", err);
        this.fetchWeatherData(
          this.state.currentCoordinate[0],
          this.state.currentCoordinate[1]
        );
      }
    );
  }

  fetchWeatherData = async (lat, lon) => {
    try {
      this.setState({ loading: true });
      // Reverse geocode
      const locUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en&key=${bigdatacloudAPIkey}`;
      const locRes = await axios.get(locUrl);
      // Weather data
      const weatherUrl = "https://api.openweathermap.org/data/3.0/onecall";
      const weatherRes = await axios.get(weatherUrl, {
        params: {
          lat,
          lon,
          exclude: "minutely,hourly",
          appid: darkskyAPIkey,
        },
      });

      const daily = weatherRes.data.daily || [];
      const forecastArray = daily.slice(0, 5).map((item) => {
        const dateStr = epochToDate(item.dt);
        return {
          dt: item.dt,
          dateStr,
          weekday: getWeekday(item.dt),
          summary: item.summary,
          conditionDesc: item.weather?.[0]?.description || "",
          precipType: capitalizeFirstLetter(item.weather?.[0]?.main || ""),
          precipProb: item.rain || 0,
          apparentMin: Math.round(item.feels_like.night - 273.15),
          apparentMax: Math.round(item.feels_like.day - 273.15),
        };
      });

      const weatherDates = daily.map((i) => epochToDate(i.dt));
      const weathertempMax = daily.map((i) => Math.round(i.temp.max - 273.15));
      const weathertempMin = daily.map((i) => Math.round(i.temp.min - 273.15));
      const windValue = daily.map((i) => Math.round(i.wind_speed));

      const baseDaylight = daily[0] ? daily[0].sunset - daily[0].sunrise : 0;
      const sunLineVals = daily.map((d, idx) => {
        if (!idx) return 0;
        const diff = (d.sunset - d.sunrise - baseDaylight) / 60;
        return Math.round(diff);
      });

      let alertMsg = [];
      if (weatherRes.data.alerts) {
        alertMsg = weatherRes.data.alerts.map((a) => a.description);
      }

      // Update the city values via callback to the wrapper.
      if (this.props.onCityChange) {
        this.props.onCityChange(locRes.data.city, locRes.data.countryName);
      }

      this.setState({
        currentCoordinate: [lat, lon],
        principalSubdivision: locRes.data.principalSubdivision,
        condition: weatherRes.data.current.weather?.[0]?.description || "",
        icon: weatherRes.data.current.weather?.[0]?.icon || "",
        temperature: Math.round(weatherRes.data.current.temp - 273.15),
        forecasts: forecastArray,
        alertMsgList: alertMsg,
        lineHistData: {
          labels: weatherDates,
          datasets: [
            {
              label: "Max Temperature",
              data: weathertempMax,
              fill: true,
              borderColor: "#3b9633",
            },
            {
              label: "Min Temperature",
              data: weathertempMin,
              fill: true,
              borderColor: "#338796",
            },
          ],
        },
        barChartData: {
          labels: weatherDates,
          datasets: [
            {
              label: "KM/H",
              backgroundColor: "rgba(12, 200, 109,0.2)",
              borderColor: "rgba(12, 200, 109,1)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(12, 200, 109,0.4)",
              hoverBorderColor: "rgba(12, 200, 109,1)",
              data: windValue,
            },
          ],
        },
        sunLineData: {
          labels: weatherDates,
          datasets: [
            {
              label: "Sun Time Gain/Loss (Minutes)",
              data: sunLineVals,
              fill: true,
              borderColor: "#FFC75F",
            },
          ],
        },
      });
    } catch (err) {
      console.error("Fetch weather error:", err);
    } finally {
      this.setState({ loading: false });
    }
  };

  searchCity = () => {
    this.setState({ loading: true });
    const mycity = document.getElementById("cities_list").value;
    if (!mycity || !cities_json[mycity]) {
      this.setState({ loading: false });
      return;
    }
    const [lat, lon] = cities_json[mycity];
    this.fetchWeatherData(lat, lon);
  };

  render() {
    const {
      loading,
      principalSubdivision,
      condition,
      temperature,
      forecasts,
      alertMsgList,
      lineHistData,
      barChartData,
      sunLineData,
      currentCoordinate,
    } = this.state;
    const { cityname, countryName, authState, cityNews,darkMode } = this.props;

    // Determine dark mode: use the passed darkMode prop or fallback to system setting.
    const isDark =
      darkMode !== undefined
        ? darkMode
        : window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches;




    return (
      <div style={styles.container}>
        <h1 style={styles.header}>City Weather & News</h1>
        <Autocomplete
          disablePortal
          id="cities_list"
          filterOptions={filterOptions}
          options={cities}
          sx={{ width: 400, marginBottom: 1 }}
          renderInput={(params) => (
            <TextField {...params} label="City" />
          )}
        />

        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: 12 }}
          onClick={this.searchCity}
          disabled={loading || !(authState?.isAuthenticated && authState?.idToken)}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <SearchIcon /> Search
            </>
          )}
        </Button>

        {temperature !== null && (
          <div>
            <h2 style={{ textAlign: "center" }}>{cityname}</h2>
            <p style={{ textAlign: "center" }}>
              {principalSubdivision}, {countryName}
            </p>
            <h3 style={{ textAlign: "center" }}>
              {capitalizeFirstLetter(condition)} | {temperature} °C
            </h3>

            <Grid2 container style={styles.grid} spacing={2} direction="row">
              {forecasts.map((fc, idx) => {
                const { bg, icon } = getForecastStyle(fc.apparentMax);
                return (
                  <Grid2 xs={8} sm={6} md={3} lg={2} xl={2} key={idx}>
                    <Box
                      bgcolor={bg}
                      color="white"
                      p={2}
                      borderRadius="8px"
                      textAlign="center"
                    >
                      <strong>
                        {fc.dateStr} {fc.weekday}
                      </strong>
                      <br />
                      Feel Like: <b>{fc.apparentMin}°C ~ {fc.apparentMax}°C</b>
                      <br />
                      {fc.summary || fc.conditionDesc}
                      <br />
                      {fc.precipType}: {fc.precipProb || 0} mm
                      <br />
                      <img src={icon} alt="temp-icon" style={{ width: 60 }} />
                    </Box>
                  </Grid2>
                );
              })}
            </Grid2>
          </div>
        )}
        {cityname && <PictureApi query={`${cityname} ${countryName}`} />}

        <br />
        {alertMsgList.map((msg, idx) => (
          <p key={idx}>
            <WarningIcon /> {msg}
          </p>
        ))}

        {lineHistData.labels?.length > 0 && (
          <div style={styles.chartArea}>
            <Line
              data={lineHistData}
              height={280}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                elements: { point: { radius: 0 } },
                plugins: {
                  tooltip: { mode: "label" },
                  hover: { mode: "dataset" },
                },
                scales: {
                  x: {
                    type: "time",
                    time: {
                      unit: "day",
                      parser: "yyyy-M-d",
                      displayFormats: { day: "yyyy-MM-dd" },
                    },
                  },
                  y: { beginAtZero: true },
                },
              }}
            />
          </div>
        )}

        {barChartData.labels?.length > 0 && (
          <div style={styles.chartArea}>
            <Bar
              data={barChartData}
              height={280}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: true,
                    text: "Wind Speed Chart",
                    font: { size: 12 },
                  },
                },
                scales: {
                  x: { ticks: { maxRotation: 90 } },
                  y: { beginAtZero: true },
                },
              }}
            />
          </div>
        )}

        {sunLineData.labels?.length > 0 && (
          <div style={styles.chartArea}>
            <Line
              data={sunLineData}
              height={280}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                elements: { point: { radius: 0 } },
                plugins: {
                  tooltip: { mode: "label" },
                  hover: { mode: "dataset" },
                },
                scales: {
                  x: {
                    type: "time",
                    time: {
                      unit: "day",
                      parser: "yyyy-M-d",
                      displayFormats: { day: "yyyy-MM-dd" },
                    },
                  },
                  y: {},
                },
              }}
            />
          </div>
        )}

        <div style={{ height: "400px", width: "100%", marginTop: 40, marginBottom: 40 }}>
          <LoadScript googleMapsApiKey={googleMapAPIkey}>
            <GoogleMap
              mapContainerStyle={{ height: "100%", width: "100%" }}
              center={{ lat: currentCoordinate[0], lng: currentCoordinate[1] }}
              zoom={9}
            >
              <Marker position={{ lat: currentCoordinate[0], lng: currentCoordinate[1] }} />
            </GoogleMap>
          </LoadScript>
        </div>

        {temperature !== null && (
          <div>
            <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
              <Divider sx={styles.divider} />
            </Box>
            <h6 style={{ textAlign: "center" }}>
              <DescriptionIcon />&nbsp;{cityname} News
            </h6>
            <NewsFeed newsData={cityNews} />
          </div>
        )}
      </div>
    );
  }
}

// --- Functional Wrapper ---
// This component holds the city state, calls the hook, and passes a callback to update the city.
const FetchWeatherApiWrapper = (props) => {
  const [cityname, setCityname] = useState(null);
  const [countryName, setCountryName] = useState(null);
  const { cityNews } = useNewsData(cityname, countryName);

  const handleCityChange = (newCityname, newCountryName) => {
    setCityname(newCityname);
    setCountryName(newCountryName);
  };

  return (
    <FetchWeatherApi
      {...props}
      cityname={cityname}
      countryName={countryName}
      cityNews={cityNews}
      onCityChange={handleCityChange}
    />
  );
};

export default withOktaAuth(FetchWeatherApiWrapper);






