import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import NotFound from './components/NotFound';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const Profile = () => {
  const { authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (authState?.isAuthenticated && authState.idToken) {
      // Directly set the claims object as user info
      setUserInfo(authState.idToken.claims);
    } else {
      setUserInfo(null);
    }
  }, [authState]);

  if (!userInfo) {
    return (
      <div>
        <NotFound />
      </div>
    );
  }

  return (
    <div>
      <div>
        <h3>
          <AccountCircleIcon />
          {' '}
          {userInfo.name}
          {' '}
        </h3>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Claim</TableCell>
                <TableCell align="left">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(userInfo).map(([key, value]) => (
                <TableRow key={`claim-${key}`}>
                  <TableCell component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell align="left">
                    {typeof value === 'object' ? JSON.stringify(value) : value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Profile;

