// hooks/useStockData.js
import { useState, useEffect } from 'react';
import axios from 'axios';

export const useStockData = (symbol, mySymbolName) => {
  const [apiData, setMyApidata] = useState(null);
  const [twitterData, setTwitterData] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [profileName, setProfileName] = useState(null);
  const [marketCap, setMarketCap] = useState(null);
  const [employeeNum, setEmployeeNum] = useState(null);
  const [homepageurl, setHomepageurl] = useState(null);
  const [logourl, setLogourl] = useState(null);
  const [price, setPrice] = useState(0);
  const [volume, setVolume] = useState(null);
  const [changepct, setChangepct] = useState(null);
  const [deltaIndicator, setdeltaIndicator] = useState(null);
  const [lowValue, setLow] = useState(0);
  const [highValue, setHigh] = useState(0);
  const [dataNews, setDataNews] = useState([]);
  const [sharesOutstanding, setSharesOutstanding] = useState(0);
  const [stockAnalyzer0, setStockAnalyzer0] = useState("");
  const [stockAnalyzer1, setStockAnalyzer1] = useState("");
  const [loading, setLoading] = useState(false);
  const [qRevenuData, setQRevenuData] = useState({});
  const [yRevenuData, setYRevenuData] = useState({});
  const [yStringText, setYStringText] = useState("");
  const [qStringText, setQStringText] = useState("");
  const [sector, setSector] = useState("");

  useEffect(() => {
    const fetchStockData = async () => {
      setLoading(true);

      const options_myapi = {
        method: "GET",
        url: "https://20211205t040925-dot-my-comment-api-dot-mingfei-test-account.appspot.com/api/comments",
      };
      const options_twitter = {
        method: "GET",
        url: 'https://twitter-api45.p.rapidapi.com/search.php',
        params: {
          query: '$' + symbol,
          search_type: 'Top'
        },
        headers: {
          "x-rapidapi-key": process.env.REACT_APP_TWITTER_KEY,
          "x-rapidapi-host": 'twitter-api45.p.rapidapi.com',
        },
      };
      const options_stock_profile = {
        method: "GET",
        url: 'https://api.polygon.io/v3/reference/tickers/' + symbol,
        params: { apiKey: process.env.REACT_APP_POLYGON_KEY } 
      };
      const options_stock_news = {
        method: 'GET',
        url: 'https://real-time-news-data.p.rapidapi.com/search',
        params: {
          query: mySymbolName + " stock",
          limit: '15',
          time_published: 'anytime',
          country: 'US',
          lang: 'en'
        },
        headers: {
          'x-rapidapi-key': process.env.REACT_APP_NEWSDATA_KEY,
          'x-rapidapi-host': 'real-time-news-data.p.rapidapi.com'
        }
      };
      const options_stock_quote = {
        method: 'GET',
        url: 'https://yahoo-finance15.p.rapidapi.com/api/v1/markets/quote',
        params: {
          ticker: symbol,
          type: 'STOCKS'
        },
        headers: {
          'x-rapidapi-key': process.env.REACT_APP_RAPIDAPI_KEY, 
          'x-rapidapi-host': 'yahoo-finance15.p.rapidapi.com'
        }
      };
      const options_ratios = {
        method: 'GET',
        url: 'https://yahoo-finance15.p.rapidapi.com/api/v1/markets/stock/modules',
        params: {
          ticker: symbol,
          module: 'statistics'
        },
        headers: {
          'x-rapidapi-key': process.env.REACT_APP_RAPIDAPI_KEY,
          'x-rapidapi-host': 'yahoo-finance15.p.rapidapi.com'
        }
      };
      const options_financial = {
        method: 'GET',
        url: 'https://yahoo-finance15.p.rapidapi.com/api/v1/markets/stock/modules',
        params: {
          ticker: symbol,
          module: 'financial-data'
        },
        headers: {
          'x-rapidapi-key': process.env.REACT_APP_RAPIDAPI_KEY,
          'x-rapidapi-host': 'yahoo-finance15.p.rapidapi.com'
        }
      };
      const options_earning = {
        method: 'GET',
        url: 'https://yahoo-finance15.p.rapidapi.com/api/v1/markets/stock/modules',
        params: {
          ticker: symbol,
          module: 'earnings'
        },
        headers: {
          'x-rapidapi-key': process.env.REACT_APP_RAPIDAPI_KEY,
          'x-rapidapi-host': 'yahoo-finance15.p.rapidapi.com'
        }
      };


      try {
        try {
          // Fetch Comments History, etc. using axios
          await axios.request(options_myapi).then(function (res) {
            let targeted_comment = []
            for (
              var tc = 0;
              tc < res.data.length;
              tc++
            ) {
              if (res.data[tc].Brand === symbol) {
                res.data[tc].updatedAt = String(res.data[tc].updatedAt).substring(0, 10)
                targeted_comment.push(res.data[tc])
              }
            }
            // Sort descending by date (most recent first)
            targeted_comment.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );

            setMyApidata(targeted_comment)

          })
        } catch (error) {
          console.error("Error fetching myapi data:", error);
        };

        try {
          const response = await axios.request(options_twitter);
          const tweets = response.data.timeline.map(tweet => ({
            text: tweet.text,
            createdAt: tweet.created_at,
            favorites: tweet.favorites
          }));

          setTwitterData(tweets);
        } catch (error) {
          console.error("Error fetching Twitter data:", error);
        };

        try {
          await axios.request(options_stock_profile)
            .then(function (response) {
              setProfileData(response.data.results.description);
              setProfileName(response.data.results.name)
              setSector(response.data.results.sic_description)
              setMarketCap(response.data.results.market_cap)
              setEmployeeNum(response.data.results.total_employees)
              setHomepageurl(response.data.results.homepage_url)
              // setLogourl(response.data.results.branding.icon_url + "?apiKey="+process.env.REACT_APP_POLYGON_KEY)
            })
        } catch (error) {
          console.error("Error fetching profileData :", error);
        };

        try {
          await axios.request(options_stock_news)
            .then(function (response) {
              setDataNews(response.data.data)
              console.log("News Api data: ",response.data);
            })
        } catch (error) {
          console.error("Error fetching options_stock_news :", error);
        };

        try {
          await axios.request(options_stock_quote)
            .then(function (response) {
              setPrice(Number((response.data.body.primaryData.lastSalePrice).replace("$", "")).toFixed(2));
              setVolume(response.data.body.primaryData.volume);
              setChangepct(response.data.body.primaryData.percentageChange);
              setdeltaIndicator(response.data.body.primaryData.deltaIndicator);
              if (
                response.data.body.keyStats.fiftyTwoWeekHighLow.value !== "NA"
              ) {
                const [lowValue, highValue] = String(response.data.body.keyStats.fiftyTwoWeekHighLow.value)
                  .split('-')
                  .map(val => parseFloat(val.trim()));
                setLow(lowValue);
                setHigh(highValue);
              }
            })
        } catch (error) {
          console.error("Error fetching profileData :", error);
        };
        try {
          await axios.request(options_ratios)
            .then(function (response) {
              setSharesOutstanding(response.data.body.sharesOutstanding.raw);


              setStockAnalyzer0("Forward PE=" + (response.data.body.forwardPE.raw).toFixed(0)
                + ", Profit Margins(%)=" + (response.data.body.profitMargins.raw * 100).toFixed(0) + "%"
                + ", short Ratio=" + response.data.body.shortRatio.raw
                + ", Book Value per share =" + response.data.body.bookValue.raw
                + ", Price To Book Ratio =" + response.data.body.priceToBook.raw
                + ", Trailing Eps=" + response.data.body.trailingEps.raw
                + ", Forward Eps=" + response.data.body.forwardEps.raw
              );
              // console.log("options_ratios: "+marketCap);
            })
        } catch (error) {
          console.error("options_ratios: " + error);
        }
        try {
          await axios.request(options_financial)
            .then(function (response) {
              setStockAnalyzer1("Current stock Price=" + response.data.body.currentPrice.raw
                + ", Total Revenue($) =" + response.data.body.totalRevenue.raw
                + ", Gross Profits($)=" + response.data.body.grossProfits.raw
                + ", Revenue Per Share($)=" + response.data.body.revenuePerShare.raw
                + ", Revenue Growth YtoY(%) =" + (response.data.body.revenueGrowth.raw * 100).toFixed(0) + "%"
                + ", Earnings Growth YtoY(%) =" + (response.data.body.earningsGrowth.raw * 100).toFixed(0) + "%"
                + ", Gross Margins =" + (response.data.body.grossMargins.raw * 100).toFixed(0) + "%"
                + ", Ebitda Margins =" + (response.data.body.ebitdaMargins.raw * 100).toFixed(0) + "%"
                + ", Operating Margins =" + (response.data.body.operatingMargins.raw * 100).toFixed(0) + "%"
                + ", Profit Margins =" + (response.data.body.profitMargins.raw * 100).toFixed(0) + "%"
                + ", Total Cash($)=" + response.data.body.totalCash.raw
                + ", Free Cashflow($)=" + response.data.body.freeCashflow.raw
                + ", Operating Cashflow($)=" + response.data.body.operatingCashflow.raw
                + ", Total Cash Per Share($)=" + response.data.body.totalCashPerShare.raw
                + ", Ebitda($)=" + response.data.body.ebitda.raw
                + ", Total Debt($)=" + response.data.body.totalDebt.raw
                + ", Quick Ratio=" + response.data.body.quickRatio.raw
                + ", Current Ratio=" + response.data.body.currentRatio.raw
                + ", Debt To Equity Ratio=" + response.data.body.debtToEquity.raw/100
                + ", Return On Assets(%)=" + (response.data.body.returnOnAssets.raw * 100).toFixed(0) + "%"
                + ", Return On Equity(%)=" + (response.data.body.returnOnEquity.raw * 100).toFixed(0) + "%"
              );
            })
        } catch (error) {
          console.error("options_ratios: " + error);
        }

        try {
          await axios.request(options_earning)
            .then(function (response) {
              ["quarterly", "yearly"].forEach((period) => {
                // Use optional chaining and a default value
                const periodData = response.data.body.earnings.financialsChart?.[period];
                if (!periodData || !symbol) {
                  console.error(`No data available for ${period}`);
                  return;
                }

                const labels = periodData.map(item => item.date);
                const revenueData = periodData.map(item => item.revenue.raw);
                const earningsData = periodData.map(item => item.earnings.raw);

                // Build chart data object for react-chartjs-2
                const earningsChartData = {
                  labels,
                  datasets: [
                    {
                      label: 'Revenue',
                      data: revenueData,
                      backgroundColor: 'rgba(255, 159, 64, 0.6)',
                      borderColor: 'rgba(255, 159, 64, 1)',
                      borderWidth: 1,
                    },
                    {
                      label: 'Profit',
                      data: earningsData,
                      backgroundColor: 'rgba(75, 192, 192, 0.6)',
                      borderColor: 'rgba(75, 192, 192, 1)',
                      borderWidth: 1,
                    },
                  ],
                };

                if (period === "quarterly") {
                  setQRevenuData(earningsChartData);
                  // Build a string from yearly data
                  const qstringText = periodData
                    .map(
                      (item) =>
                        `Quarterly ${item.date}, Revenue=$${item.revenue.raw}, Profit=$${item.earnings.raw}`
                    )
                    .join("; ");
                  setQStringText(qstringText);
                } else if (period === "yearly") {
                  setYRevenuData(earningsChartData);
                  // Build a string from yearly data
                  const ystringText = periodData
                    .map(
                      (item) =>
                        `Year ${item.date}, Revenue=$${item.revenue.raw}, Profit=$${item.earnings.raw}`
                    )
                    .join("; ");
                  setYStringText(ystringText);

                }
              });


            })
        } catch (error) {
          console.error("Earnings error: " + error);
        }

      } catch (error) {
        console.error("API call has at least 1 error: " + error);
      } finally { setLoading(false); }


    }

    // When symbol changes, fetch the stock data.
    if (symbol) {
      fetchStockData(symbol);
    }
  }, [symbol,mySymbolName]);

  return {
    apiData, twitterData, profileData, profileName,sector, marketCap, employeeNum, homepageurl, logourl,
    price, volume, changepct, deltaIndicator, lowValue, highValue, dataNews, stockAnalyzer0, stockAnalyzer1,
    sharesOutstanding, loading,
    qRevenuData, yRevenuData,yStringText,qStringText

  };
};


