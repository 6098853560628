import React from 'react';
import "./css/Home.css";
import AssessmentIcon from '@mui/icons-material/Assessment';

import Python from './components/Python';
// import Container from '@mui/material/Container';

function Charts() {

  return (

    <div>
      <h3><AssessmentIcon /> World News</h3>

      <Python />

    </div>
  );
}

export default Charts;
