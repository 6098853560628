import React from 'react';
import { Typography, Box } from '@mui/material';

export default class NotFound extends React.Component {
  render() {
    return (
      <Box
        sx={{
          height: '100vh',
          backgroundColor: '#f5f5f5',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          p: 2,
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: '8rem',
            fontWeight: 'bold',
            color: 'primary.main',
            mb: 2,
          }}
        >
          404
        </Typography>
        <Typography variant="h4" component="h2" sx={{ color: 'text.secondary' }}>
          Page Not Found
        </Typography>
      </Box>
    );
  }
}




