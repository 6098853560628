// src/styles/stockStyles.js
const styles = (theme) => ({
    root: {
      flexGrow: 1,
    },
    tabs: {
      marginTop: 20,
    },
    TableContainer: {
      marginTop: 10,
      marginBottom: 10,
      minWidth: 275,
      maxWidth: 475,
    },
    ScoreCardStrongBuy: {
      marginTop: 15,
      marginBottom: 15,
      minWidth: 100,
      maxWidth: 150,
      padding: 5,
      backgroundColor: 'rgba(0, 128, 0, 0.9)', // Dark green
      textAlign: 'center',
    },
    ScoreCardBuy: {
      marginTop: 15,
      marginBottom: 15,
      minWidth: 100,
      maxWidth: 150,
      padding: 5,
      backgroundColor: 'rgba(6, 201, 42, 0.9)', // Bright green
      textAlign: 'center',
    },
    ScoreCardHold: {
      marginTop: 15,
      marginBottom: 15,
      minWidth: 100,
      maxWidth: 150,
      padding: 5,
      backgroundColor: 'rgba(255, 193, 7, 0.9)', // Yellow
      textAlign: 'center',
    },
    ScoreCardSell: {
      marginTop: 15,
      marginBottom: 15,
      minWidth: 100,
      maxWidth: 150,
      padding: 5,
      backgroundColor: 'rgba(255, 87, 34, 0.9)', // Orange
      textAlign: 'center',
    },
    ScoreCardStrongSell: {
      marginTop: 15,
      marginBottom: 15,
      minWidth: 100,
      maxWidth: 150,
      padding: 5,
      backgroundColor: 'rgba(227, 59, 109, 0.9)', // Red
      textAlign: 'center',
    },
    table: {
      maxWidth: 300,
      marginTop: 10,
    },
    title: {
      fontSize: 18,
    },
    chart: {
      minHeight: 280,
      marginTop: 15,
      marginBottom: 15,
      display: "block",
      position: "relative",
    },
    inline: {
      display: 'inline',
    },
    grid: {
      marginTop: 5,
      marginBottom: 5,
    },
    chip: {
      margin: 5,
    },
  });
  
  export { styles };
  