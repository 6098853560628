// src/components/TwitterFeed.jsx
import React from 'react';
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';

const TwitterFeed = ({ twitterData }) => {
  if (!twitterData || twitterData.length === 0) return null;
  return (
    <div>
      <Typography variant="h6"><TwitterIcon /> Twitter Feed</Typography>
      <List>
        {twitterData.map((tweet, index) => (
          <ListItem key={index}>
            <ListItemText primary={tweet.text} secondary={new Date(tweet.createdAt).toLocaleString()} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default TwitterFeed;
