import React, { useState } from "react";
import {
  Typography,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Link
} from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import LaunchIcon from '@mui/icons-material/Launch';
import AiSummarization from './AiSummarization';
import PictureApi from "./PictureApi";

// Mapping from URL values to display text (with flag emojis as needed)
const menuLabels = {
  "https://www.lefigaro.fr/": "🇫🇷 France: Le Figaro",
  "https://www.lemonde.fr/": "🇫🇷 France: Le Monde",
  "https://www.leparisien.fr/": "🇫🇷 France: Le Parisien",
  "https://www.cnn.com/": "🇺🇸 United States: CNN",
  "https://www.foxnews.com/": "🇺🇸 United States: FOX News",
  "https://www.telegraph.co.uk/": "🇬🇧 United Kingdom: Telegraph",
  "https://www.krone.at/": "🇦🇹 Austria: Krone",
  "https://www.lesoir.be/": "🇧🇪 Belgique: Lesoir",
  "https://www.dnevnik.bg/": "🇧🇬 Bulgaria: Dnevnik",
  "https://www.jutarnji.hr/": "🇭🇷 Croatia: Jutarnji List",
  "https://cyprus-mail.com/": "🇨🇾 Cyprus: Cyprus Mail",
  "https://www.idnes.cz/": "🇨🇿 Czech Republic: iDNES.cz",
  "https://www.dr.dk/": "🇩🇰 Denmark: DR Nyheder",
  "https://www.postimees.ee/": "🇪🇪 Estonia: Postimees",
  "https://www.hs.fi/": "🇫🇮 Finland: Helsingin Sanomat",
  "https://www.spiegel.de/": "🇩🇪 Germany: Der Spiegel",
  "https://www.kathimerini.gr/": "🇬🇷 Greece: Kathimerini",
  "https://index.hu/": "🇭🇺 Hungary: Index.hu",
  "https://www.irishtimes.com/": "🇮🇪 Ireland: The Irish Times",
  "https://www.corriere.it/": "🇮🇹 Italy: Corriere della Sera",
  "https://www.diena.lv/": "🇱🇻 Latvia: Diena",
  "https://www.delfi.lt/": "🇱🇹 Lithuania: Delfi",
  "https://www.wort.lu/": "🇱🇺 Luxembourg: Luxemburger Wort",
  "https://timesofmalta.com/": "🇲🇹 Malta: Times of Malta",
  "https://www.telegraaf.nl/": "🇳🇱 Netherlands: De Telegraaf",
  "https://wyborcza.pl/": "🇵🇱 Poland: Gazeta Wyborcza",
  "https://www.publico.pt/": "🇵🇹 Portugal: Público",
  "https://adevarul.ro/": "🇷🇴 Romania: Adevărul",
  "https://www.sme.sk/": "🇸🇰 Slovakia: SME",
  "https://www.delo.si/": "🇸🇮 Slovenia: Delo",
  "https://elpais.com/": "🇪🇸 Spain: El País",
  "https://www.dn.se/": "🇸🇪 Sweden: Dagens Nyheter",
  "https://www.poder360.com.br/": "Brazil: Poder360",
  "https://www.clarin.com/": "Argentina: Clarín",
  "https://elcomercio.pe/": "Peru: El Comercio",
  "https://www.emol.com/": "Chile: El Mercurio",
  "https://www.eltiempo.com/": "Colombia: El Tiempo",
  "https://listindiario.com/": "Dominican Republic: Listín Diario",
  "https://www.eluniversal.com.mx/": "Mexico: El Universal",
  "https://www.laprensagrafica.com/": "El Salvador: La Prensa Gráfica",
};

export default function RunPythonScript() {
  const [output, setOutput] = useState("");
  // const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState("https://www.lefigaro.fr/");
  const { authState } = useOktaAuth();
  const user_name = authState?.idToken?.claims?.name;

  const handleUrlChange = (event) => {
    setSelectedUrl(event.target.value);
  };

  // Function to run the Python script and get news text
  const runScript = async () => {
    setLoading(true);
    setOutput("");
    // setSummary("");
    try {
      const response = await fetch(
        "https://pythonapi-dot-mingfei-test-account.appspot.com/run-python",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ url: selectedUrl }),
        }
      );
      const data = await response.json();
      setOutput(data.output);
    } catch (error) {
      console.error("Error running Python script:", error);
      setOutput("Error executing script");
    } finally {
      setLoading(false);
    }
  };

  const content0 = {
    role: "developer",
    content: "You are a helpful assistant."
  };

  const content1 = {
    role: "user",
    content: `Below is an extraction of text from a news website. Please detect its language and provide a clear, concise summary in English of the important news events. Ignore any non-news information such as menu items, or any text that is unclear or irrelevant.\n\n${output}`
  };


  const firstSpaceIndex = menuLabels[selectedUrl].indexOf(' ');
  const colonIndex = menuLabels[selectedUrl].indexOf(':');
  const country = menuLabels[selectedUrl].substring(firstSpaceIndex + 1, colonIndex).trim();
  return (
    <div style={{ padding: "20px" }}>
      <FormControl fullWidth style={{ marginBottom: "20px" }}>
        <InputLabel id="url-select-label">Select News Website</InputLabel>
        <Select
          labelId="url-select-label"
          value={selectedUrl}
          onChange={handleUrlChange}
        >
          {Object.keys(menuLabels).map((url) => (
            <MenuItem key={url} value={url}>
              {menuLabels[url]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {authState?.isAuthenticated && authState?.idToken ? (
        <>
          {/* Run Button to fetch news content */}
          <Button variant="contained" onClick={runScript} disabled={loading}>
            {loading ? "Running..." : "Run"}
          </Button>

          {output && (
            <div style={{ marginTop: "20px" }}>
              <Link
                href={selectedUrl}
                target="_blank"
                rel="noopener"
                underline="hover"
                style={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  color: "#1976d2",
                  textDecoration: "none",
                }}
              >
                {menuLabels[selectedUrl]} <LaunchIcon />
              </Link>
            </div>
          )}
        </>
      ) : (
        <>
          {/* Disabled Run Button */}
          <Button variant="contained" disabled>
            {loading ? "Running..." : "Run"}
          </Button>

          {output && (
            <div style={{ marginTop: "20px" }}>
              <Link
                href={selectedUrl}
                target="_blank"
                rel="noopener"
                underline="hover"
                style={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  color: "#1976d2",
                  textDecoration: "none",
                }}
              >
                {menuLabels[selectedUrl]}
              </Link>
            </div>
          )}

          {output && (
            <div style={{ marginTop: "20px" }}>
              <Button variant="contained" disabled color="secondary">
                {loading
                  ? "Summarizing..."
                  : `AI Summarization requested by ${user_name}`}
              </Button>
            </div>
          )}
        </>
      )}

      {output && (<PictureApi query={`${country}`} />)}
      {/* Summarization Result */}
      <AiSummarization
        content0={content0}
        content1={content1}
        input={output}
        user_name={user_name}
        Descriptions={menuLabels[selectedUrl]}
        Brand={menuLabels[selectedUrl]}
      />

      {/* Output Section for extracted news text */}
      {output && (<div style={{ marginTop: "20px" }}>
        <Typography variant="h6">
          <NewspaperIcon /> Newspaper:
        </Typography>
        <Typography
          variant="body1"
          style={{
            backgroundColor: "#f0f0f0",
            color: "#000",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <span
            style={{
              display: "block",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {output}
          </span>
        </Typography>
      </div>)}

      {loading && (
        <div style={overlayStyle}>
          <CircularProgress style={spinnerStyle} />
        </div>
      )}
    </div>
  );
}

// Styles for overlay and spinner
const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const spinnerStyle = {
  color: "#ffffff",
};








