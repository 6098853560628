// src/components/Comments.jsx
import React from 'react';
import { Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import ForumIcon from '@mui/icons-material/Forum';

const Comments = ({ comments, classes }) => {
  if (!comments || comments.length === 0) return null;

  return (
    <div>
      <Typography variant="h6"><ForumIcon /> Historical Data </Typography>
      {comments.map((mycomment) => (
        <List dense={false} key={mycomment._id}>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={mycomment.Description}
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    User: {mycomment.user_name}
                  </Typography>
                  <Typography variant="body2">
                    {mycomment.comment_name}
                  </Typography>
                  <Typography variant="body2">
                    Date: {mycomment.updatedAt}
                  </Typography>
                </>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
      ))}
    </div>
  );
};

export default Comments;

