import React from "react";
import axios from "axios";
import PageviewIcon from "@mui/icons-material/Pageview";
import { withOktaAuth } from "@okta/okta-react";
import NotFound from "./NotFound";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import StarsIcon from "@mui/icons-material/Stars";
import ErrorIcon from "@mui/icons-material/Error";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Typography, TextField } from "@mui/material";

// Import our custom data table
import CustomDataTable from "./CustomDataTable";

// -------------------------------------------------------------------------
// 1) Exports so Shirt.jsx can import { api_url, axiosdata }
// -------------------------------------------------------------------------
export const api_url =
  "https://20211205t040925-dot-my-comment-api-dot-mingfei-test-account.appspot.com/api/comments";

// 'axiosdata' is also used in another jsx, so we do not remove it:
export const axiosdata = (url) => {
  return axios.get(url);
};

// -------------------------------------------------------------------------
// 2) Helper: get only YYYY-MM-DD from a date string.
// -------------------------------------------------------------------------
function dateSubstring(dateStr) {
  return String(dateStr).substring(0, 10);
}

// -------------------------------------------------------------------------
// 3) Define columns for the data table
// -------------------------------------------------------------------------
const columns = [
  {
    name: "Select",
    selector: (row) => row.url,
  },
  {
    name: "Symbol",
    selector: (row) => row.Brand,
  },
  {
    name: "Title",
    selector: (row) => row.Description,
  },
  {
    name: "Rating",
    selector: (row) => row.Rating,
  },
  {
    name: "Note",
    selector: (row) => row.comment_name,
    wrap: true, // Force text wrapping in the comment column
  },
  {
    name: "Date",
    selector: (row) => row.updatedAt,
  },
];

// -------------------------------------------------------------------------
// 4) Main Component
// -------------------------------------------------------------------------
class FetchMongoDbAPI extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      apiData: [],
      loading: true,
      userInfo: null,
      showAddReview: false,
      filterText: "", // Added filter state
    };

    // Use environment variable or fallback:
    this.local_url =
      process.env.LOCAL_URL || "https://www.snowbirdcloud.com";
  }

  async componentDidMount() {
    this._isMounted = true;

    // 1) Check user info if authenticated.
    if (this.props.authState?.isAuthenticated && this.props.authState?.idToken) {
      if (this._isMounted) {
        this.setState({
          userInfo: this.props.authState.idToken.claims.name,
        });
      }
    } else {
      this.setState({ userInfo: null });
    }

    // 2) Fetch data from your API using async/await.
    try {
      const res = await axiosdata(api_url);
      if (!res.data) {
        this.setState({ loading: false });
        return;
      }

      // Transform fetched data: add diffDays, update date format, and add a button.
      // Then, filter to include only rows that belong to the logged-in user.
      const updatedData = res.data
        .map((item) => {
          const itemUrl = `${this.local_url}/comments/${item._id}`;
          let btn;
          if (item.Rating > 6) {
            btn = (
              <Button href={itemUrl} style={{ color: "green" }}>
                <StarsIcon />
              </Button>
            );
          } else if (item.Rating < 3) {
            btn = (
              <Button href={itemUrl} style={{ color: "pink" }}>
                <ErrorIcon />
              </Button>
            );
          } else {
            btn = (
              <Button href={itemUrl}>
                <KeyboardArrowRightIcon />
              </Button>
            );
          }

          // Calculate "age" in days:
          const diffDays = Math.ceil(
            Math.abs(new Date() - new Date(item.updatedAt)) / (1000 * 60 * 60 * 24)
          );

          return {
            ...item,
            url: btn,
            updatedAt: dateSubstring(item.updatedAt),
            diffDays,
          };
        })
        // Filter so that only items where item.user_name equals the current user's name remain.
        .filter(
          (item) =>
            item.user_name === this.props.authState.idToken.claims.name
        );

      // Sort the data by updatedAt in descending order.
      updatedData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

      this.setState({ apiData: updatedData, loading: false });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Toggle the "Add Review" component.
  hideComponent = (componentName) => {
    if (componentName === "showHideMyReview") {
      this.setState((prev) => ({ showAddReview: !prev.showAddReview }));
    }
  };

  render() {
    const { loading, apiData, userInfo, filterText } = this.state;
    const { authState } = this.props;

    if (loading) {
      return <NotFound />;
    }
    if (!apiData) {
      return <div>did not get any data</div>;
    }

    const isDataReady = apiData.length > 0 && userInfo;

    // Filter the data based on the "Symbol" (Brand) and "Note" (comment_name) fields.
    const filteredData = apiData.filter((item) =>
      item.Brand.toLowerCase().includes(filterText.toLowerCase()) ||
      item.comment_name.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
      <div>
        {isDataReady && (
          <div>
            <p>
              <PageviewIcon /> Welcome, {userInfo}! Here is your Search History:
            </p>
          </div>
        )}

        {authState?.isAuthenticated && authState?.idToken ? (
          <section>
            {/* Filter input field using Material-UI TextField */}
            <div style={{ marginBottom: "1rem" }}>
              <TextField
                label="Filter by Symbol or Note"
                variant="outlined"
                fullWidth
                value={filterText}
                onChange={(e) =>
                  this.setState({ filterText: e.target.value })
                }
              />
            </div>
            <CustomDataTable
              title="HISTORY"
              columns={columns}
              data={filteredData}
            />
          </section>
        ) : (
          <Typography
            style={{
              textAlign: "center",
              marginTop: "20px",
              fontSize: "1.2rem",
            }}
          >
            <LockOpenIcon /> Please Login / Sign Up
          </Typography>
        )}
      </div>
    );
  }
}

export default withOktaAuth(FetchMongoDbAPI);









