import { api_url } from "./FetchMongoDbAPI";
import React, { useState, useEffect } from "react";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import axios from "axios";
import {
  Chip,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Input,
  Box
} from "@mui/material";
import useClasses from "../UseClass";
import { useTheme } from "@mui/material/styles";

// Import and register Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  TimeScale,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  TimeScale,
  Tooltip,
  Legend,
  Title
);

const styles = (theme) => ({
  chartarea: {
    display: "flex",
    position: "relative",
    marginTop: 25,
    minHeight: 280,
    maxHeight: 420,
  },
  formControl: {
    margin: 15,
    minWidth: 120,
    maxWidth: 600,
    display: "flex",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 250,
    },
  },
};

const getStyles = (name, currentArray, theme) => ({
  fontWeight:
    currentArray.indexOf(name) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
});

const aggregateParamData = (myChartDataFlat = []) => {
  const helper = {};
  const result = [];
  myChartDataFlat.forEach((item) => {
    const key = item.Symbole;
    if (!helper[key]) {
      helper[key] = { ...item };
      result.push(helper[key]);
    } else {
      helper[key].FW_PE += item.FW_PE;
      helper[key].Gross_Margin += item.Gross_Margin;
      helper[key].Net_Profit += item.Net_Profit;
      helper[key].PEG_ratio += item.PEG_ratio;
      helper[key].Short_ratio += item.Short_ratio;
      helper[key].ROE += item.ROE;
      helper[key].Current_ratio += item.Current_ratio;
      helper[key].count += item.count;
    }
  });
  return result;
};

const buildParamChartData = (aggregated = []) => {
  const labels = [];
  const fwpe = [];
  const gm = [];
  const np = [];
  const peg = [];
  const sr = [];
  const roe = [];
  const cur = [];

  aggregated.forEach((item) => {
    labels.push(item.Symbole);
    fwpe.push(item.FW_PE / item.count);
    gm.push(item.Gross_Margin / item.count);
    np.push(item.Net_Profit / item.count);
    peg.push(item.PEG_ratio / item.count);
    sr.push(item.Short_ratio / item.count);
    roe.push(item.ROE / item.count);
    cur.push(item.Current_ratio / item.count);
  });

  return {
    labels,
    datasets: [
      {
        label: "FW PE",
        backgroundColor: "rgba(48, 102, 190,0.2)",
        borderColor: "rgba(48, 102, 190,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(48, 102, 190,0.4)",
        hoverBorderColor: "rgba(48, 102, 190,1)",
        data: fwpe,
      },
      {
        label: "Gross Margin",
        backgroundColor: "rgba(17, 157, 164,0.2)",
        borderColor: "rgba(17, 157, 164,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(17, 157, 164,0.4)",
        hoverBorderColor: "rgba(17, 157, 164,1)",
        data: gm,
      },
      {
        label: "Net Margin",
        backgroundColor: "rgba(109, 157, 197,0.2)",
        borderColor: "rgba(109, 157, 197,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(109, 157, 197,0.4)",
        hoverBorderColor: "rgba(109, 157, 197,1)",
        data: np,
      },
      {
        label: "PEG Ratio",
        backgroundColor: "rgba(128, 222, 217,0.2)",
        borderColor: "rgba(128, 222, 217,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(128, 222, 217,0.4)",
        hoverBorderColor: "rgba(128, 222, 217,1)",
        data: peg,
      },
      {
        label: "Short Ratio",
        backgroundColor: "rgba(174, 236, 239,0.2)",
        borderColor: "rgba(174, 236, 239,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(174, 236, 239,0.4)",
        hoverBorderColor: "rgba(174, 236, 239,1)",
        data: sr,
      },
      {
        label: "ROE",
        backgroundColor: "rgba(227, 102, 214,0.2)",
        borderColor: "rgba(227, 102, 214,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(227, 102, 214,0.4)",
        hoverBorderColor: "rgba(227, 102, 214,1)",
        data: roe,
      },
      {
        label: "Current",
        backgroundColor: "rgba(19, 16, 163,0.2)",
        borderColor: "rgba(19, 16, 163,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(19, 16, 163,0.4)",
        hoverBorderColor: "rgba(19, 16, 163,1)",
        data: cur,
      },
    ],
  };
};

const groupByMeanRating = (dataFlat = [], brandKey = "Brand") => {
  const grouping = dataFlat.reduce((acc, item) => {
    const arr = acc[item[brandKey]] || [];
    arr.push(item.Rating);
    acc[item[brandKey]] = arr;
    return acc;
  }, {});

  const result = Object.entries(grouping).map(([brand, ratingArray]) => {
    const sum = ratingArray.reduce((a, b) => a + b, 0);
    const avg = sum / ratingArray.length || 0;
    return { symbole: brand, average_rating: avg };
  });

  return result.sort((a, b) => (a.average_rating > b.average_rating ? 1 : -1));
};

const buildLineChartData = (dataFlat = []) => {
  const grouping = dataFlat.reduce((acc, it) => {
    const dStr = it.createdAt.substring(0, 10);
    acc[dStr] = (acc[dStr] || 0) + 1;
    return acc;
  }, {});
  const labels = Object.keys(grouping);
  const counts = Object.values(grouping);
  return {
    labels,
    datasets: [
      {
        label: "Daily Query",
        data: counts,
        fill: true,
        borderColor: "#338796",
      },
    ],
  };
};

const BarChart = () => {
  const classes = useClasses(styles);
  const theme = useTheme();

  // Chart states
  const [dataChart, setDataChart] = useState({ labels: [], datasets: [] });
  const [lineHistData, setLineHistData] = useState({ labels: [], datasets: [] });
  const [paraChart, setParaChart] = useState({ labels: [], datasets: [] });
  const [sugChartdata, setSugChartdata] = useState({ labels: [], datasets: [] });
  // Selector states
  const [selector1, setSelector1] = useState([]);
  const [selector2, setSelector2] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  // Data states
  const [chartSource, setChartSource] = useState([]);
  const [groupBySum, setGroupBySum] = useState([]);
  const [paraResult, setParaResult] = useState([]);
  const [labelList, setLabelList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(api_url);
        const rawData = Array.isArray(res.data) ? res.data : [];
        const myChartData = rawData.map((item) => ({
          Brand: item.Brand,
          Description: item.Description,
          Rating: item.Rating,
          createdAt: item.createdAt,
          parameter: item.parameter || [],
        }));
        setChartSource(myChartData);

        const cutoff = new Date();
        cutoff.setDate(cutoff.getDate() - 60);

        const myChartDataFlat = [];
        myChartData.forEach((el) => {
          if (!el.createdAt) return;
          const dateObj = new Date(el.createdAt.substring(0, 10));
          if (el.parameter && el.parameter.length > 0 && dateObj > cutoff) {
            myChartDataFlat.push({
              createdAt: el.createdAt.substring(0, 10),
              Symbole: el.Brand,
              sug: el.Description.substring(0, 5).replace("[", "").replace("]", ""),
              FW_PE: el.parameter[0].FW_PE,
              Gross_Margin: el.parameter[0].Gross_Margin,
              Net_Profit: el.parameter[0].Net_Profit,
              PEG_ratio: el.parameter[0].PEG_ratio,
              Short_ratio: el.parameter[0].Short_ratio,
              ROE: el.parameter[0].ROE,
              Current_ratio: el.parameter[0].Current_ratio,
              current_to_200: el.parameter[0].current_to_200,
              Rating: el.Rating,
              count: 1,
            });
          }
        });

        const paramAggregated = aggregateParamData(myChartDataFlat);
        setParaResult(paramAggregated);
        setParaChart(buildParamChartData(paramAggregated));

        const sugHelper = {};
        myChartDataFlat.forEach((x) => {
          if (!sugHelper[x.sug]) sugHelper[x.sug] = 0;
          sugHelper[x.sug] += 1;
        });
        const sugLabels = Object.keys(sugHelper);
        const sugCounts = Object.values(sugHelper);
        setSuggestions(sugLabels);
        setSugChartdata({
          labels: sugLabels,
          datasets: [
            {
              label: "Recommendation",
              backgroundColor: [
                "rgba(245, 240, 208,0.8)",
                "rgba(244, 208, 245,0.8)",
                "rgba(52, 245, 117,0.8)",
              ],
              hoverBackgroundColor: [
                "rgba(245, 240, 208,0.4)",
                "rgba(244, 208, 245,0.4)",
                "rgba(52, 245, 117,0.4)",
              ],
              borderColor: [
                "rgba(245, 240, 208,1)",
                "rgba(244, 208, 245,1)",
                "rgba(52, 245, 117,1)",
              ],
              borderWidth: 1,
              data: sugCounts,
            },
          ],
        });

        const ratingDataLast60 = myChartData.filter(
          (el) => new Date(el.createdAt) > cutoff
        );
        const groupedAvg = groupByMeanRating(ratingDataLast60);
        const filteredGroupedAvg = groupedAvg.filter(
          (obj) => obj.average_rating !== 0
        );
        setGroupBySum(filteredGroupedAvg);
        const brandLabels = filteredGroupedAvg.map((obj) => obj.symbole);
        const brandValues = filteredGroupedAvg.map((obj) => obj.average_rating);
        setLabelList(brandLabels);
        setDataChart({
          labels: brandLabels,
          datasets: [
            {
              label: "Avg Stock Watcher Score",
              backgroundColor: "rgba(12, 200, 109,0.2)",
              borderColor: "rgba(12, 200, 109,1)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(12, 200, 109,0.4)",
              hoverBorderColor: "rgba(12, 200, 109,1)",
              data: brandValues,
            },
          ],
        });

        setLineHistData(buildLineChartData(myChartData));
        console.log("Daily query: ", buildLineChartData(myChartData));
      } catch (err) {
        console.error("Error fetching chart data:", err);
      }
    };

    fetchData();
  }, []);

  const handleReset = () => {
    setSelector1([]);
    setSelector2([]);
    setParaChart(buildParamChartData(paraResult));
    const brandLabels = groupBySum.map((el) => el.symbole);
    const brandValues = groupBySum.map((el) => el.average_rating);
    setDataChart({
      labels: brandLabels,
      datasets: [
        {
          label: "Avg Stock Watcher Score",
          backgroundColor: "rgba(12, 200, 109,0.2)",
          borderColor: "rgba(12, 200, 109,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(12, 200, 109,0.4)",
          hoverBorderColor: "rgba(12, 200, 109,1)",
          data: brandValues,
        },
      ],
    });
    setLineHistData(buildLineChartData(chartSource));
  };

  const handleChangeSelector = async (event) => {
    const chosenBrands = event.target.value;
    setSelector1(chosenBrands);
    const mySelector2 = selector2.length === 0 ? suggestions : selector2;
    const filteredData = buildFilteredData(chosenBrands, mySelector2);
    buildNewCharts(filteredData, chosenBrands);
  };

  const handleSugSelector = async (event) => {
    const chosenSug = event.target.value;
    setSelector2(chosenSug);
    const brandArr = selector1.length === 0 ? labelList : selector1;
    const filteredData = buildFilteredData(brandArr, chosenSug);
    buildNewCharts(filteredData, brandArr);
  };

  const buildFilteredData = (brands, suggestionsArr) => {
    const filtered = [];
    chartSource.forEach((el) => {
      if (el.parameter && el.parameter.length > 0) {
        const sugLabel = el.Description.substring(0, 5)
          .replace("[", "")
          .replace("]", "");
        if (brands.includes(el.Brand) && suggestionsArr.includes(sugLabel)) {
          filtered.push({
            createdAt: el.createdAt.substring(0, 10),
            Symbole: el.Brand,
            sug: sugLabel,
            Rating: el.Rating,
            FW_PE: el.parameter[0].FW_PE,
            Gross_Margin: el.parameter[0].Gross_Margin,
            Net_Profit: el.parameter[0].Net_Profit,
            PEG_ratio: el.parameter[0].PEG_ratio,
            Short_ratio: el.parameter[0].Short_ratio,
            ROE: el.parameter[0].ROE,
            Current_ratio: el.parameter[0].Current_ratio,
            current_to_200: el.parameter[0].current_to_200,
            count: 1,
          });
        }
      }
    });
    return filtered;
  };

  const buildNewCharts = (flatData, selectedBrands) => {
    const aggregated = aggregateParamData(flatData);
    setParaChart(buildParamChartData(aggregated));

    const groupedAvg = groupByMeanRating(flatData, "Symbole");
    const newLabels = [];
    const newValues = [];
    groupedAvg.forEach((x) => {
      if (selectedBrands.includes(x.symbole)) {
        newLabels.push(x.symbole);
        newValues.push(x.average_rating);
      }
    });
    setDataChart({
      labels: newLabels,
      datasets: [
        {
          label: "avg score",
          backgroundColor: "rgba(12, 200, 109,0.2)",
          borderColor: "rgba(12, 200, 109,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(12, 200, 109,0.4)",
          hoverBorderColor: "rgba(12, 200, 109,1)",
          data: newValues,
        },
      ],
    });

    setLineHistData(buildLineChartData(flatData));
  };

  return (
    <div>
      <div>
        <FormControl fullWidth sx={{ mb: 2, minWidth: 300, mt: 5 }}>
          <InputLabel id="demo-mutiple-chip-label">Symbole</InputLabel>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={selector1}
            onChange={handleChangeSelector}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((val) => (
                  <Chip key={val} label={val} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {(labelList || []).map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, selector1, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2, minWidth: 300 }}>
          <InputLabel id="sug-selector-label">Suggestions</InputLabel>
          <Select
            labelId="sug-selector-label"
            id="sug-selector-chip"
            multiple
            value={selector2}
            onChange={handleSugSelector}
            input={<Input id="select-sug-selector-chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((val) => (
                  <Chip key={val} label={val} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {(suggestions || []).map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, selector2, theme)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          sx={{ m: 1 }}
          onClick={handleReset}
          variant="contained"
          color="primary"
        >
          Reset
        </Button>
      </div>
      <div className={classes.chartarea}>
        <Bar
          data={dataChart}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: "Avg Scores (60 Days)",
                font: { size: 12 },
              },
            },
            scales: {
              x: { ticks: { maxRotation: 90 } },
              y: { beginAtZero: true },
            },
          }}
        />
      </div>
      <div className={classes.chartarea}>
        <Bar
          data={paraChart}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: "Fundamentals (60 Days)",
                font: { size: 12 },
              },
            },
            scales: {
              x: { ticks: { maxRotation: 90 } },
              y: { beginAtZero: true },
            },
          }}
        />
      </div>
      <div className={classes.chartarea}>
        <Doughnut
          data={sugChartdata}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: "Recommendation",
                font: { size: 12 },
              },
              legend: { display: true },
            },
          }}
        />
      </div>
      <div className={classes.chartarea}>
        <Line
          data={lineHistData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: { title: { display: false } },
            scales: {
              x: {
                type: "time",
                time: {
                  unit: "day",
                  displayFormats: { day: "yyyy-MM-dd" },
                },
              },
              y: {},
            },
          }}
        />
      </div>
    </div>
  );
};

export default BarChart;








