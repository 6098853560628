import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

const CustomDataTable = ({ title, columns, data }) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        p: 2,
        mb: 2,
      }}
    >
      {title && (
        <Typography
          variant="h6"
          sx={{ mb: 2, textAlign: 'center', color: theme.palette.text.primary }}
        >
          {title}
        </Typography>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell
                  key={col.selector}
                  sx={{
                    fontWeight: 'bold',
                    backgroundColor: theme.palette.action.hover,
                    color: theme.palette.text.primary,
                  }}
                >
                  {col.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                sx={{
                  '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.selected },
                }}
              >
                {columns.map((col) => (
                  <TableCell key={col.selector} sx={{ color: theme.palette.text.primary }}>
                    {col.selector(row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CustomDataTable;
