// src/components/NewsFeed.jsx
import React from 'react';
import { Typography, List, ListItem, ListItemText, Link } from '@mui/material';

const NewsFeed = ({ newsData }) => {
  if (!newsData || newsData.length === 0) return null;
  return (
    <div>
      <List>
        {newsData.map((news, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={news.title}
              secondary={
                <>
                  <Typography variant="caption">
                    {news.source_name} - {news.published_datetime_utc}
                    <br />
                    {news.snippet}
                  </Typography>
                  <br />
                  <Link href={news.link} target="_blank" rel="noopener">Read More</Link>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default NewsFeed;
