import React from 'react';
import "./css/Home.css";
import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChart from './components/BarChart';

function Charts() {
  return (
    <div>
      <h3><AssessmentIcon/> Statistics</h3>
      <BarChart />
      </div>
  );
}

export default Charts;
