import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  IconButton,
  Tooltip,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';

const local_url = process.env.LOCAL_URL || "https://www.snowbirdcloud.com";
const commentsApiUrl = "https://20211205t040925-dot-my-comment-api-dot-mingfei-test-account.appspot.com/api/comments";

const CommentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [comment, setComment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  // Editing states
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [editableRating, setEditableRating] = useState('');
  const [editableDescription, setEditableDescription] = useState('');
  const [editableCommentName, setEditableCommentName] = useState('');
  const [message, setMessage] = useState('');

  // Delete modal state
  const [showModal, setShowModal] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  // Fetch the comment data
  useEffect(() => {
    axios.get(`${commentsApiUrl}/${id}`)
      .then(response => {
        setComment(response.data);
        setLoading(false);
        // Initialize editing fields
        setEditableRating(response.data.Rating);
        setEditableDescription(response.data.Description);
        setEditableCommentName(response.data.comment_name);
      })
      .catch(err => {
        console.error('Error fetching comment:', err);
        setError(err);
        setLoading(false);
      });
  }, [id]);


  // Toggle read-only mode. If saving, call handleUpdate.
  const toggleReadOnly = (e) => {
    e.preventDefault();
    if (isReadOnly) {
      setIsReadOnly(false);
    } else {
      // Save changes
      handleUpdate();
      setIsReadOnly(true);
    }
  };

  // Handle update (PUT request)
  const handleUpdate = () => {
    const putUrl = `${commentsApiUrl}/${comment._id}`;
    setMessage("Saving...");
    axios.put(putUrl, {
      Rating: editableRating,
      Description: editableDescription,
      comment_name: editableCommentName,
    })
      .then(res => {
        console.log("Update success:", res.data);
        setMessage("Saved!");
        // Update local comment state with new values
        setComment({
          ...comment,
          Rating: editableRating,
          Description: editableDescription,
          comment_name: editableCommentName
        });
      })
      .catch(err => {
        console.error("Update error:", err.response);
        setMessage("Error saving changes.");
      });
  };

  // Handle delete (with modal confirmation)
  const handleDeleteX = (e) => {
    e.preventDefault();
    const inputVal = document.getElementById("item_delete_confirm").value;
    if (inputVal === comment.Brand) {
      axios.delete(`${commentsApiUrl}/${comment._id}`)
        .then(res => {
          console.log("Delete success:", res.data);
          navigate("/review"); // redirect after deletion
        })
        .catch(err => {
          console.error("Delete error:", err.response);
          setDeleteError("Error deleting the comment.");
        });
    } else {
      setDeleteError("Incorrect Input");
    }
  };

  if (loading) return <Typography>Loading comment data...</Typography>;
  if (error) return <Typography color="error">Error loading comment: {error.message}</Typography>;
  if (!comment) return <Typography>No comment found.</Typography>;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {comment.Brand}
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1"><strong>Rating:</strong> {comment.Rating}</Typography>
        <Typography variant="body1"><strong>Description:</strong> {comment.Description}</Typography>
        {/* {price !== null && (
          <Box>
            <Typography variant="body1"><strong>Current Price:</strong> ${price}</Typography>
            {perc !== null && (
              <Typography variant="body1">
                <strong>Change:</strong>{" "}
                <span style={{ color: perc > 0 ? "green" : "red" }}>
                  {perc > 0 ? `+${perc}%` : `${perc}%`}
                </span>
              </Typography>
            )}
          </Box>
        )} */}
        <Typography variant="body1"><strong>User Name:</strong> {comment.user_name}</Typography>
        <Typography variant="body1">
          <strong>Created At:</strong> {new Date(comment.createdAt).toLocaleString()}
        </Typography>
        <Typography variant="body1">
          <strong>Updated At:</strong> {new Date(comment.updatedAt).toLocaleString()}
        </Typography>
      </Box>

      <Box component="hr" sx={{ borderColor: "grey.300", my: 2 }} />

      <Box component="form" noValidate autoComplete="off" sx={{ mb: 2 }}>
        <TextField
          id="review-input"
          label="Review (Comment Name)"
          value={editableCommentName}
          onChange={(e) => setEditableCommentName(e.target.value)}
          InputProps={{ readOnly: isReadOnly }}
          fullWidth
          multiline
          sx={{ mb: 2 }}
        />
        <Typography variant="body2" sx={{ mb: 2 }}>{message}</Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mb: 2 }}>
          <Button variant="outlined" href={`${local_url}/review`} sx={{ m: 1 }}>
            Return
          </Button>

          {isReadOnly ? (
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                onClick={() => setIsReadOnly(false)}
                variant="contained"
                color="primary"
                sx={{ m: 1 }}
              >
                Update
              </Button>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => setShowModal(true)}
                  sx={{ m: 1 }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                onClick={toggleReadOnly}
                variant="contained"
                color="primary"
                sx={{ m: 1 }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setIsReadOnly(true);
                  setEditableRating(comment.Rating);
                  setEditableDescription(comment.Description);
                  setEditableCommentName(comment.comment_name);
                  setMessage("");
                }}
                variant="outlined"
                sx={{ m: 1 }}
              >
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={showModal}>
          <Box sx={{ p: 2, outline: "none", backgroundColor: "background.paper", borderRadius: 1, width: "100%", maxWidth: 400 }}>
            <TextField
              id="item_delete_confirm"
              label={`Type ${comment.Brand} to confirm`}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            />
            {deleteError && (
              <Typography color="error" sx={{ mb: 2 }}>
                {deleteError}
              </Typography>
            )}
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                variant="contained"
                startIcon={<CancelIcon />}
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteX}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default CommentDetail;

















