import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PictureApi = ({ query }) => {
    // State to store a single photo object
    const [photo, setPhoto] = useState(null);

    // Replace with your Unsplash access key
    const accessKey = process.env.REACT_APP_UNSPLASH_KEY;

    useEffect(() => {
        const fetchPhoto = async () => {
            try {
                // Construct the URL using the provided query
                const url = `https://api.unsplash.com/search/photos?query=${encodeURIComponent(query)}&client_id=${accessKey}`;
                const response = await axios.get(url);
                const results = response.data.results;
                if (results.length > 0) {
                    // Select one random photo from the results
                    const randomIndex = Math.floor(Math.random() * results.length);
                    setPhoto(results[randomIndex]);
                } else {
                    setPhoto(null);
                }
            } catch (error) {
                console.error('Error fetching photo:', error);
            }
        };

        if (query) {
            fetchPhoto();
        }
    }, [query, accessKey]);

    return (
        <div>
            {photo && (
                <div>
                    <img
                        src={photo.urls.regular}
                        alt={photo.description || 'Photo'}
                        style={{
                            borderRadius: '12px',
                            width: '100%',
                            maxWidth: '600px',
                            display: 'block',
                            margin: '20px auto',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease'
                        }}
                        onMouseOver={e => {
                            e.currentTarget.style.transform = 'scale(1.02)';
                            e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
                        }}
                        onMouseOut={e => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.15)';
                        }}
                    />
                    <div style={{ marginTop: '10px', fontSize: '0.9em', color: '#555',textAlign: 'center' }}>
                        Photo by{' '}
                        <a
                            href={photo.user.portfolio_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none', color: '#0073e6' }}
                        >
                            {photo.user.name} from Unsplash
                        </a>
                    </div>
                </div>
            )}
        </div>

    );
};

export default PictureApi;

