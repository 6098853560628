import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  Link,
  CircularProgress,
  Stack,
  Alert,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useOktaAuth } from '@okta/okta-react';

const SearchLocalBusinessPage = () => {
  const { authState } = useOktaAuth();
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    setError(false); // Reset error state before making the API call

    const options = {
      method: 'GET',
      url: 'https://local-business-data.p.rapidapi.com/search',
      params: {
        query: query,
        limit: '30',
        zoom: '13',
        language: 'en',
      },
      headers: {
        'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPI_KEY,
        'X-RapidAPI-Host': 'local-business-data.p.rapidapi.com',
      },
    };

    try {
      const response = await axios.request(options);

      if (response.data?.data?.length > 0) {
        const simplifiedData = response.data.data
          .filter(
            (item) =>
              item.review_count &&
              item.rating !== null
          )
          .map((item) => ({
            name: item.name,
            review_count: item.review_count,
            rating: item.rating,
            full_address: item.full_address,
            phone_number: item.phone_number,
            place_link: item.place_link,
            photo_url:
              item.photos_sample?.[0]?.photo_url ||
              "../public/checkmark.png",
          }))
          .sort((a, b) => b.review_count - a.review_count);

        setData(simplifiedData);
      } else {
        throw new Error('No data returned from API');
      }
    } catch (error) {
      console.error(error);
      setError(true); // Set error state if the request fails
    } finally {
      setLoading(false);
    }
  };

  const renderRating = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;
    return (
      <Stack direction="row">
        {[...Array(fullStars)].map((_, i) => (
          <StarIcon key={i} sx={{ color: '#ffc107' }} />
        ))}
        {halfStar === 1 && <StarHalfIcon sx={{ color: '#ffc107' }} />}
        {[...Array(emptyStars)].map((_, i) => (
          <StarBorderIcon key={i} sx={{ color: '#ffc107' }} />
        ))}
      </Stack>
    );
  };

  return (
    <Box sx={{ position: 'relative', minHeight: '100vh' }}>
      <Box sx={{ maxWidth: 800, margin: '0 auto', textAlign: 'center', my: 4 }}>
        <TextField
          fullWidth
          label="Search for Local Businesses"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          margin="normal"
        />
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{ my: 2 }}
          disabled={!authState?.isAuthenticated || loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Search'}
        </Button>
      </Box>

      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1200,
          }}
        >
          <CircularProgress sx={{ color: '#ffffff' }} />
        </Box>
      )}

      {!loading && error && (
        <Alert severity="error" sx={{ mx: 4 }}>
          API request failed, please try it later (or exceed the monthly limit).
        </Alert>
      )}

      {!loading && !error && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {data.map((item, index) => (
            <Paper
              key={index}
              elevation={3}
              sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, p: 2 }}
            >
              {item.photo_url && (
                <Box
                  sx={{
                    flexShrink: 0,
                    overflow: 'hidden',
                    width: 160,
                    height: 120,
                    borderRadius: '4px',
                  }}
                >
                  <img
                    src={item.photo_url}
                    alt={item.name}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              )}
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6">
                  <Link
                    href={item.place_link}
                    target="_blank"
                    rel="noopener"
                    sx={{ fontWeight: 'bold' }}
                  >
                    {item.name}
                  </Link>
                </Typography>
                {renderRating(item.rating)}
                <Typography>Rating: {item.rating}</Typography>
                <Typography>Review Count: {item.review_count}</Typography>
                <Typography>Address: {item.full_address}</Typography>
                <Typography>Phone: {item.phone_number}</Typography>
              </Box>
            </Paper>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default SearchLocalBusinessPage;




